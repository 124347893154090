import '@ungap/custom-elements';
import register from 'preact-custom-element';
import RetailerDialog from './components/retailer-dialog';
import RetailerSearch from './components/retailer-search';
import './animation';
import './styles/index.scss';
customElements.define('walls-retailer-dialog', RetailerDialog, {
    extends: 'div',
});
register(RetailerSearch, 'walls-retailer-search', [
    'range',
]);
