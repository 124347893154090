import gsap from './gsap';
function animate(container) {
    const q = gsap.utils.selector(container);
    const lines = q('.js-heading-line');
    const image = q('.js-sausage-roll');
    const timeline = gsap.timeline({
        delay: 0.25,
        scrollTrigger: {
            trigger: container,
            scrub: true,
            start: 'top center',
            end: 'bottom bottom',
        },
    });
    gsap.set(lines, {
        opacity: 0,
        y: '50%',
        transformOrigin: '50% 50%',
    });
    gsap.set(image, {
        rotate: 180,
    });
    timeline.to(image, {
        rotate: 364.5,
        duration: 2,
    });
    timeline.to(lines, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        stagger: 0.2,
    }, '<');
}
animate('#favourite');
