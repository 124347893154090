export const ranges = {
    'rolls': {
        name: 'Rolls',
        products: [{
                name: 'Jumbo Sausage Roll',
                retailers: ['asda', 'tesco'],
                impulse: true,
                image: {
                    src: '/images/products/thumbs/rolls/1-jumbo-sausage-roll.png',
                    width: 154,
                    height: 400,
                },
            }, {
                name: 'Vegan Jumbo Roll',
                retailers: ['asda', 'tesco'],
                impulse: true,
                image: {
                    src: '/images/products/thumbs/rolls/2-vegan-jumbo-roll.png',
                    width: 122,
                    height: 400,
                },
            }, {
                name: 'Jumbo Cheese + Onion Roll',
                impulse: true,
            }, {
                name: 'Jumbo Breakfast Roll',
                impulse: true,
            }, {
                name: 'Jumbo Chicken Roll',
                impulse: true,
            }, {
                name: '2pk Sausage Rolls',
                retailers: ['asda', 'tesco'],
                impulse: true,
                image: {
                    src: '/images/products/thumbs/rolls/6-sausage-rolls.png',
                    width: 320,
                    height: 400,
                },
            }, {
                name: '4pk Sausage Rolls',
                retailers: ['asda', 'tesco'],
                impulse: true,
                image: {
                    src: '/images/products/thumbs/rolls/7-sausage-rolls.png',
                    width: 322,
                    height: 400,
                },
            }, {
                name: '10pk Sausage Rolls',
                retailers: ['tesco'],
                image: {
                    src: '/images/products/thumbs/rolls/8-sausage-rolls.png',
                    width: 400,
                    height: 192,
                },
            }, {
                name: '10pk Snack Sausage Rolls',
                retailers: ['tesco'],
                image: {
                    src: '/images/products/thumbs/rolls/9-snack-sausage-rolls.png',
                    width: 400,
                    height: 192,
                },
            }, {
                name: '12 Cocktail Sausage Rolls',
                retailers: ['asda'],
                image: {
                    src: '/images/products/thumbs/rolls/10-cocktail-sausage-rolls.png',
                    width: 400,
                    height: 208,
                },
            }, {
                name: '14 Mini Sausage Rolls',
                retailers: ['asda'],
            }, {
                name: '4 Pork + Pickle Sausage Rolls',
                retailers: ['asda', 'tesco'],
                image: {
                    src: '/images/products/thumbs/rolls/12-pork-and-pickle-sausage-rolls.png',
                    width: 322,
                    height: 400,
                },
            }, {
                name: '4 Cheese + Pickle Rolls',
                retailers: ['asda', 'tesco'],
                image: {
                    src: '/images/products/thumbs/rolls/13-cheese-and-pickle-rolls.png',
                    width: 324,
                    height: 400,
                },
            }],
    },
    'pasties-and-slices': {
        name: 'Pasties & Slices',
        products: [{
                name: 'Chicken + Bacon Slice',
                impulse: true,
                image: {
                    src: '/images/products/thumbs/pasties-and-slices/1-chicken-and-bacon-slice.png',
                    width: 226,
                    height: 400,
                },
            }, {
                name: 'Peppered Steak Slice',
                impulse: true,
                image: {
                    src: '/images/products/thumbs/pasties-and-slices/2.2-peppered-steak-slice.png',
                    width: 226,
                    height: 400,
                },
            }, {
                name: 'Chicken + Mushroom Slice',
                impulse: true,
                image: {
                    src: '/images/products/thumbs/pasties-and-slices/3-chicken-and-mushroom-slice.png',
                    width: 226,
                    height: 400,
                },
            }, {
                name: 'Chicken Tikka Slice',
                impulse: true,
                image: {
                    src: '/images/products/thumbs/pasties-and-slices/4-chicken-tikka-slice.png',
                    width: 226,
                    height: 400,
                },
            }, {
                name: 'Ham + Cheese Slice',
                impulse: true,
                image: {
                    src: '/images/products/thumbs/pasties-and-slices/5-ham-and-cheese-slice.png',
                    width: 226,
                    height: 400,
                },
            }, {
                name: 'Cheese + Onion Slice',
                impulse: true,
                image: {
                    src: '/images/products/thumbs/pasties-and-slices/6-cheese-and-onion-slice.png',
                    width: 226,
                    height: 400,
                },
            }, {
                name: 'Classic Pasty',
                impulse: true,
            }],
    },
    'pies': {
        name: 'Pies',
        products: [{
                name: 'Medium Pork Pie',
                retailers: ['iceland'],
                impulse: true,
            }, {
                name: '4pk Mini Pork Pies',
                retailers: ['iceland'],
                impulse: true,
                image: {
                    src: '/images/products/thumbs/pies/2-mini-pork-pies.png',
                    width: 400,
                    height: 292,
                },
            }, {
                name: '4pk Mini Pork + Pickle Pies',
                retailers: ['iceland'],
                impulse: true,
            }, {
                name: '6pk Mini Pork Pies',
                retailers: ['iceland'],
                impulse: true,
                image: {
                    src: '/images/products/thumbs/pies/4-mini-pork-pies.png',
                    width: 400,
                    height: 222,
                },
            }],
    },
    'snacking': {
        name: 'Rolls',
        products: [{
                name: 'Scotch Egg',
                impulse: true,
                image: {
                    src: '/images/products/thumbs/snacking/1-scotch-egg.png',
                    width: 400,
                    height: 352,
                },
            }, {
                name: 'Scotch Egg Twinpack',
                impulse: true,
                image: {
                    src: '/images/products/thumbs/snacking/2-scotch-egg-twin-pack.png',
                    width: 206,
                    height: 400,
                },
            }, {
                name: '18 × 12g Mini Scotch Eggs',
                impulse: true,
                image: {
                    src: '/images/products/thumbs/snacking/3-mini-scotch-eggs.png',
                    width: 256,
                    height: 400,
                },
            }, {
                name: '14 × 12g Breaded Southern Fried Chicken Straws',
                impulse: true,
                image: {
                    src: '/images/products/thumbs/snacking/4-southern-fried-chicken-straws.png',
                    width: 252,
                    height: 400,
                },
            }],
    },
};
