import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { ranges } from '../data/range';
export default function RetailerSearch({ range }) {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showProducts, setShowProducts] = useState(false);
    useEffect(() => {
        setSelectedProduct(null);
        setShowProducts(false);
    }, [range]);
    if (!range) {
        return null;
    }
    if (ranges[range] === undefined) {
        return (h("div", null,
            h("strong", null,
                "Invalid range key '",
                range,
                "'")));
    }
    function handleClick() {
        setShowProducts((showResults) => !showResults);
    }
    function select(product) {
        setSelectedProduct(product);
        setShowProducts(false);
    }
    return (h("div", { className: 'search' },
        h("div", { className: 'bg-white  fg-red' },
            h("button", { type: 'button', onClick: handleClick, className: 'search__button  font-heading  bg-white  fg-red' },
                h("span", { className: 'search__label' }, selectedProduct ? selectedProduct.name : 'Choose product'),
                h("svg", { xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 30 26', width: 30, height: 26, className: 'search__icon  fill-current' },
                    h("polygon", { points: '0 0, 30 0, 15 26' }))),
            h("div", { hidden: !showProducts, className: 'search__options' }, ranges[range].products.map((product) => (h(ProductButton, { key: product.name, product: product, select: () => select(product), selected: selectedProduct === product }))))),
        h(RetailerResults, { product: selectedProduct })));
}
function ProductButton({ product, select, selected }) {
    const { image } = product;
    return (h("button", { type: 'button', onClick: select, className: `product-button  ${selected ? 'product-button--selected' : ''}  font-heading` },
        h("svg", { xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 400 400', width: 400, height: 400, className: 'product-button__canvas  fill-current' }, image ? (h("image", { xlinkHref: image.src, x: (400 - image.width) / 2, y: (400 - image.height) / 2, width: image.width, height: image.height })) : (h("rect", { width: '100%', height: '100%', opacity: 0.5 }))),
        h("span", { className: 'product-button__label' }, product.name)));
}
function RetailerResults({ product }) {
    var _a;
    if (product === null) {
        return null;
    }
    const retailers = (_a = product.retailers) !== null && _a !== void 0 ? _a : [];
    const showLogos = retailers.length > 0;
    return (h("div", { className: 'retailers  search__results' },
        showLogos && (h("ul", { className: 'retailers__list' }, retailers.map((retailer) => (h("li", { key: retailer, className: 'retailers__item  bg-white' },
            h(RetailerLogo, { retailer: retailer })))))),
        product.impulse && (h("p", { className: 'wrapper  retailers__note  font-heading  align-center' },
            "This product is ",
            showLogos ? 'also' : '',
            " available from impulse stores. This includes local corner shops and garages."))));
}
function RetailerLogo({ retailer }) {
    const Component = RetailerLogos[retailer];
    return h(Component, null);
}
const RetailerLogos = {
    asda: AsdaLogo,
    iceland: IcelandLogo,
    tesco: TescoLogo,
};
function AsdaLogo() {
    return (h("img", { src: '/images/retailers/asda.svg', alt: 'Asda', width: 110, height: 32, className: 'retailers__logo  retailers__logo--asda' }));
}
function IcelandLogo() {
    return (h("img", { src: '/images/retailers/iceland.svg', alt: 'Iceland', width: 329, height: 73, className: 'retailers__logo  retailers__logo--iceland' }));
}
function TescoLogo() {
    return (h("img", { src: '/images/retailers/tesco.svg', alt: 'Tesco', width: 176, height: 51, className: 'retailers__logo  retailers__logo--tesco' }));
}
