import gsap from './gsap';
function animateHands(container) {
    const q = gsap.utils.selector(container);
    const hands = q('.js-hand');
    gsap.set(hands, {
        x: (index) => {
            return ['-65%', '-50%', '75%'][index];
        },
        y: (index) => {
            return ['-5%', '75%', '50%'][index];
        },
    });
    gsap.to(hands, {
        x: 0,
        y: 0,
        scrollTrigger: {
            trigger: container,
            scrub: true,
            start: 'top center',
            end: 'bottom bottom',
        },
    });
}
animateHands('#everyone');
