import gsap from './gsap';
function animate(container) {
    const q = gsap.utils.selector(container);
    const caption = q('.js-caption');
    gsap.set(caption, {
        opacity: 0,
        scale: 0.5,
        transformOrigin: '50% 50%',
    });
    gsap.to(caption, {
        opacity: 1,
        scale: 1,
        scrollTrigger: {
            trigger: container,
            scrub: true,
            start: 'top center',
            end: 'bottom center',
        },
    });
}
animate('#image-overlay');
