import A11yDialog from 'a11y-dialog';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
export default class RetailerDialog extends HTMLDivElement {
    constructor() {
        super();
        this.dialog = null;
        this.handleClick = this.handleClick.bind(this);
    }
    connectedCallback() {
        this.dialog = new A11yDialog(this);
        this.dialog.on('show', () => {
            disablePageScroll();
        });
        this.dialog.on('hide', () => {
            enablePageScroll();
            this.updateRange(null);
        });
        document.body.addEventListener('click', this.handleClick);
    }
    show(range) {
        var _a;
        (_a = this.dialog) === null || _a === void 0 ? void 0 : _a.show();
        this.updateRange(range);
    }
    handleClick({ target }) {
        if (target instanceof HTMLButtonElement && target.dataset.range) {
            this.show(target.dataset.range);
        }
    }
    updateRange(range) {
        const search = this.querySelector('walls-retailer-search');
        if (search === null) {
            return;
        }
        if (range === null) {
            search.removeAttribute('range');
        }
        else {
            search.setAttribute('range', range);
        }
    }
}
