import gsap from './gsap';
function animateCaption(container, startingPosition) {
    const q = gsap.utils.selector(container);
    const lines = q('.js-large-caption text');
    gsap.set(lines, {
        x: `${startingPosition}%`,
        opacity: 0,
    });
    gsap.to(lines, {
        x: 0,
        opacity: 1,
        stagger: 0.2,
        scrollTrigger: {
            trigger: container,
            scrub: true,
            start: 'top center',
            end: 'bottom bottom',
        },
    });
}
function animateOverlay(container, startingPosition) {
    const q = gsap.utils.selector(container);
    const overlay = q('.js-figure-overlay');
    gsap.set(overlay, {
        x: `${startingPosition}%`,
    });
    gsap.to(overlay, {
        x: 0,
        scrollTrigger: {
            trigger: container,
            scrub: true,
            start: 'top center',
            end: 'bottom bottom',
        },
    });
}
animateCaption('#eggs-figure', -25);
animateCaption('#roll-figure', 25);
animateOverlay('#eggs-figure', 100);
animateOverlay('#roll-figure', -50);
